import { useConfirm } from "primevue/useconfirm";
import { useGet, usePost } from "~/composables/api/customFetch/useCustomFetch";
import type { RestaurantType } from "~/types/entities/restaurant";
import type { PlaceRecommendation } from "~/types/entities/placeRecommendation.type";

export function useRestaurants() {
  const { t } = useI18n();
  const confirm = useConfirm();
  const searchRestaurants = async (query: string): Promise<RestaurantType[]> => {
    try {
      const { data } = await useGet<RestaurantType[]>("restaurant/search/" + query, {
        disableGlobalLoader: true,
      });
      return data.value ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const searchRestaurantsAccordingToPlace = async (
    searchKey: string,
  ): Promise<PlaceRecommendation[]> => {
    try {
      // Send the autocomplete request
      const { data } = await useGet<{
        adresser: PlaceRecommendation[];
      }>("https://ws.geonorge.no/adresser/v1/sok?sok=" + searchKey, { disableGlobalLoader: true });
      // Return the search results
      return data.value?.adresser ?? [];
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const getRestaurantsByLatLn = async (location: Partial<PlaceRecommendation>) => {
    try {
      const { data } = await usePost<RestaurantType | null>("/restaurant/byaddress", {
        body: {
          address: {
            streetName: location.adressenavn,
            streetNr: location.nummer,
            postalCode: location.postnummer,
            stairWay: location.bokstav || "",
            floor: null,
            addressDescription: null,
            longDescription: null,
          },
        },
      });
      return data.value ?? null;
    } catch (error) {
      confirm.require({
        message: t("restaurantNotFound"),
        header: "Alert",
        icon: "pi pi-exclamation-triangle",
        rejectClass: "hidden",
      });
      return null;
    }
  };
  return {
    searchRestaurants,
    searchRestaurantsAccordingToPlace,
    getRestaurantsByLatLn,
  };
}
