<template>
  <div
    id="search-box"
    ref="searchBox"
    class="w-full flex flex-row justify-content-center align-items-center relative"
  >
    <InputText
      v-model="searchkey"
      :placeholder="$t('locationSearchPlaceHolder')"
      class="w-full"
      @focus="toggle"
    />
    <OverlayPanel
      ref="op"
      class="search-overlay-container"
      :pt="{ root: { parent: 'search-box' } }"
      :style="{
        width: `${searchBoxWidth}px`,
        maxHeight: '15rem',
        overflowY: 'scroll',
      }"
    >
      <div
        v-for="option of options"
        :key="option.adressetekst"
        class="search-item"
        @click="handleSelect(option)"
      >
        <p class="title">{{ option.adressetekst }}</p>
        <div class="w-full flex flex-row justify-content-between align-items-center">
          <p class="subtitle">{{ option.poststed }}, {{ option.postnummer }}</p>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { useRestaurants } from "~/composables/api/restaurants/useRestaurants";
import type { PlaceRecommendation } from "~/types/entities/placeRecommendation.type";
import { useOrderMethodStore } from "~/store/orderMethodSlice";

const porps = defineProps({
  thirdPartyUse: {
    type: Boolean,
    default: false,
  },
});

const orderMethodStore = useOrderMethodStore();
const searchkey = ref<string>("");
const op = ref();
const searchBox = ref();
const searchBoxWidth = ref(100);
const options = ref<PlaceRecommendation[]>([]);
const emit = defineEmits(["option-selected", "selected-address"]);
const { searchRestaurantsAccordingToPlace, getRestaurantsByLatLn } = useRestaurants();
const search = useDebounceFn(
  () => searchkey.value && searchRestaurantsAccordingToPlace(searchkey.value),
  100,
);
watch(
  () => [searchBox.value],
  ([newSearchBox]) => {
    searchBoxWidth.value = newSearchBox?.offsetWidth ?? searchBoxWidth.value ?? 100;
  },
);
watch(
  () => searchkey.value,
  async () => {
    options.value = await search();
  },
);

const toggle = (event) => {
  op.value.toggle(event);
};

const handleSelect = async (item: PlaceRecommendation) => {
  if (!porps.thirdPartyUse) {
    op.value.hide();
    const restaurant = await getRestaurantsByLatLn(item);
    if (restaurant) {
      orderMethodStore.setSelectedLocation(item);
      orderMethodStore.setSelectedRestaurantForDelivery(restaurant);
      emit("option-selected", restaurant);
    }
  } else {
    searchkey.value = item.adressenavn;
    op.value.hide();
    emit("selected-address", item);
  }
};
</script>
